import $ from "cash-dom";
import {Cash} from "cash-dom/dist/cash";
import anime = require("animejs");

enum DropdownState {
    ON,
    OFF,
}

let CURRENT_STATE = DropdownState.OFF;
let CURRENT_ACTIVE = null;

// initiated on `initNavigationDropdown`
let MODAL: Cash;

export function initNavigationDropdown() {

    // this should be called after window load;
    MODAL = $("#modal-bg");

    const dropSelector = $("li[data-dropdown]")
    dropSelector.each(function (idx, li_dropdown) {

        let menuElement = $(li_dropdown);
        menuElement.on("click", ev => {
            ev.stopPropagation();


            // close dropdown if we click at the selector one more time
            if (menuElement.data("dropdown") == CURRENT_ACTIVE) {
                closeDropdown(dropSelector, menuElement);
                return;
            }
            openDropdown(dropSelector, menuElement);
        });

        MODAL.on("click", ev => {
            ev.stopPropagation();
            closeDropdown(dropSelector, menuElement);
        });
    })

}

function handleCarets(coll: Cash, clickedElement?: Cash) {
    coll.children().removeClass("fa-caret-up");

    if (clickedElement ?? false) {
        clickedElement.children().addClass("fa-caret-up");
        return;
    }
}


function openDropdown(menuElements, clickedElement: Cash) {
    CURRENT_STATE = DropdownState.ON;
    CURRENT_ACTIVE = clickedElement.data("dropdown");

    if (MODAL.hasClass("hidden")) {
        MODAL.toggleClass("hidden");
    }

    handleCarets(menuElements, clickedElement);

    const dropdownChildren = $("#modal-bg").children();
    dropdownChildren.addClass("hidden");

    const name = clickedElement.data("dropdown");
    $(`#${name}`).removeClass("hidden");

}

function closeDropdown(menuElements: Cash, clickedElement: Cash) {
    CURRENT_STATE = DropdownState.OFF;
    CURRENT_ACTIVE = null;

    MODAL.addClass("hidden");
    handleCarets(menuElements, null);
}