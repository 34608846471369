import $ from "cash-dom";
import MagicGrid from "magic-grid";
import Splide from "@splidejs/splide";
import offside from 'offside-js';
import {initNavigationDropdown} from "./navDropdown";


require("fslightbox");

function isMobile(): boolean {
    return window.innerWidth < 640;
}

function showFormSuccessBox() {

    const query: string = window.location.search;
    const splitted: string[] = query.split("=", 2);

    if (splitted[0] === "?form") {
        if (splitted[1] === "success") {
            $("#form-success").removeClass("hidden");
        }
    }
}

function initOffside() {
    const offcanvasMenuId = "#sidemenu";
    const slidingElementsSelectorId = "#main";

    offside(offcanvasMenuId, {
        slidingElementsSelector: slidingElementsSelectorId,
        buttonsSelector: "#close-offcanvas, #open-offcanvas",
        slidingSide: "left",


        // fixes initial "flashing" of the menu on load
        beforeOpen: () => {
            $(offcanvasMenuId).removeClass('hidden');
        }

    });
}

function initCarousel() {
    const target = $(".splide").first();
    let lastState = null;
    let lastSplide: Splide = null;

    if (target[0] ?? false) {


        const initSplide = () => {

            if (lastState == isMobile()) {
                window.requestAnimationFrame(initSplide);
                return;
            }
            lastState = isMobile();
            let perPage = isMobile() ? 1 : 3;

            const splideOptions = {
                arrows: true,
                perMove: 1,
                perPage,
                type: 'loop',
                classes: {
                    pagination: 'splide__pagination',
                    page: 'splide__pagination__page !bg-neutral-800'
                }
            };

            if (lastSplide !== null) {
                lastSplide.destroy();
            }

            lastSplide = new Splide(target[0], splideOptions);
            lastSplide.mount();
            window.requestAnimationFrame(initSplide);
        }
        initSplide();
    }

}

window.onload = function (a) {

    initCarousel();
    initNavigationDropdown();
    initOffside();
    showFormSuccessBox();

    new MagicGrid({
        container: ".magic-grid",
        static: true,
        animate: true
    }).listen();
}